
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { IMessage, IValidRequest } from '@/utils/types'
import { Prop, Watch } from 'vue-property-decorator'
import { ACTION_APP, ACTION_MESSAGES } from '@/store/actions'
import MessagesFormModal from '@/components/messages/MessagesFormModal.vue'
import cloneDeep from 'lodash/cloneDeep'
import { Formater } from '@/utils/formater'
import CLoading from '@/components/common/ui/CLoading.vue'
import TagContent from '@/components/common/ui/TagContent.vue'

@Options({
  components: {
    MessagesFormModal,
    CLoading,
    TagContent,
  },
  directives: { maska },
})
export default class Messages extends Vue {
  @Prop()
  appId!: string

  selectedMessage: IMessage = {}
  modalVisible = false
  loading = true

  get messages(): IMessage[] {
    return this.$store.getters.messages
  }

  async onCopy(data: IMessage) {
    this.modalVisible = true
    const messageCopy = cloneDeep(data)
    messageCopy._id = ''
    messageCopy.title = messageCopy.title + ' Copy'
    this.selectedMessage = messageCopy
  }

  onAdd() {
    this.modalVisible = true
    this.selectedMessage = {
      app_id: this.appId,
      messages: [{ type: 'message' }],
    }
  }

  onCloseModal() {
    this.modalVisible = false
  }

  onEdit(data: IMessage) {
    this.selectedMessage = data
    this.modalVisible = true
  }

  async onDelete(messages: IMessage) {
    this.$q
      .dialog({
        title: this.$t('confirm'),
        message: this.$t('are_you_sure_you_want_to_delete'),
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const exists = await this.checkItemExists(messages)
        if (!exists) {
          const success = await this.$store.dispatch(ACTION_MESSAGES.DELETE, messages._id)
          if (success) {
            this.$q.notify({
              message: this.$t('messages.deleted'),
              color: 'positive',
            })
          }
        }
      })
  }

  @Watch('appId', { immediate: true })
  async appIdChanged() {
    if (!this.appId) {
      return
    }

    await this.$store.dispatch(ACTION_MESSAGES.LOAD_ITEMS, {
      app_id: this.appId,
      is_include_data: true,
    })

    this.loading = false
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  async checkItemExists(message: IMessage) {
    const { dispatch } = this.$store
    const params: IValidRequest = {
      app_id: this.appId,
      initial_id: '',
      push_id: '',
      card_id: message._id ?? '',
      card_type: 'message',
      total_messages: 0,
      campaign: {},
    }
    const resp = await dispatch(ACTION_APP.CHECK_ITEM_EXISTS, params)

    if (resp.campaign_title) {
      this.$q.dialog({
        title: this.$t('error'),
        message: this.$t('messages.item_exists_in_campaign', { names: resp.campaign_title.toString() }),
        persistent: true,
        html: true,
      })
      return true
    }
    return false
  }
}
