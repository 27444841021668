<template>
  <q-tabs no-caps dense align="left" v-model="tabs" class="text-grey" active-color="primary" indicator-color="primary">
    <q-tab name="messages" :label="$t('menu.asset_message')" />
    <q-tab name="question_answer" :label="$t('menu.q_a')" />
    <q-tab name="goal" :label="$t('menu.goal')" />
    <q-tab name="form" :label="$t('menu.form')" />
  </q-tabs>
  <q-separator />
  <q-tab-panels v-model="tabs" animated class="q-mt-md">
    <q-tab-panel name="messages" class="q-pa-none no-border">
      <Messages :appId="appId" />
    </q-tab-panel>
    <q-tab-panel name="question_answer" class="q-pa-none no-border">
      <Questions :appId="appId" v-if="appId" @update:changeDefault="changeDefault" />
    </q-tab-panel>
    <q-tab-panel name="goal" class="q-pa-none no-border">
      <Goals :appId="appId" v-if="appId" @update:changeDefault="changeDefault" />
    </q-tab-panel>
    <q-tab-panel name="form" class="q-pa-none no-border">
      <Forms :appId="appId" v-if="appId" @update:changeDefault="changeDefault" />
    </q-tab-panel>
  </q-tab-panels>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import Questions from '@/components/question/Questions.vue'
import Goals from '@/components/goal/Goals.vue'
import Messages from '@/components/messages/Messages.vue'
import Forms from '@/components/form/Forms.vue'

@Options({
  components: {
    Messages,
    Questions,
    Goals,
    Forms,
  },
  directives: { maska },
  emits: ['update:changeDefault'],
})
export default class AssetsTabsPanel extends Vue {
  get appId() {
    return this.$route.params.app_id
  }

  bar = 'messages'
  get tabs() {
    return this.bar
  }

  set tabs(tab: string) {
    this.bar = tab
  }

  changeDefault(event) {
    this.$emit('update:changeDefault', event)
  }
}
</script>
<style lang="sass" scoped></style>
