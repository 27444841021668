import { render } from "./AssetsTabsPanel.vue?vue&type=template&id=6aba869f"
import script from "./AssetsTabsPanel.vue?vue&type=script&lang=ts"
export * from "./AssetsTabsPanel.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTabs,QTab,QSeparator,QTabPanels,QTabPanel});
