<template>
  <AssetsTabsPanel />
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import AssetsMixin from '@/components/assets/mixins/AssetsMixin.vue'
import AssetsTabsPanel from '@/components/assets/_partials/AssetsTabsPanel.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'

@Options({
  components: {
    AssetsTabsPanel,
  },
  directives: { maska },
})
export default class Assets extends mixins(AssetsMixin, BaseFormMixin) {}
</script>
<style lang="sass" scoped></style>
