
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'

@Options({
  components: {},
  directives: { maska },
})
export default class AssetsMixin extends Vue {
  get selectedAppId() {
    return this.$route.params.app_id
  }
}
