
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import draggable from 'vuedraggable'

import { IGoal, IValidRequest } from '@/utils/types'
import { ACTION_APP, ACTION_GOAL } from '@/store/actions'
import { calcOrderingDragend } from '@/utils/helpers'
import GoalFormModal from './GoalFormModal.vue'
import cloneDeep from 'lodash/cloneDeep'
import { Formater } from '@/utils/formater'
import CLoading from '@/components/common/ui/CLoading.vue'
import TagContent from '@/components/common/ui/TagContent.vue'

@Options({
  components: {
    draggable,
    GoalFormModal,
    CLoading,
    TagContent,
  },
  directives: { maska },
})
export default class Goals extends Vue {
  @Prop()
  appId!: string

  dragging = false
  modalVisible = false
  selectedGoal: IGoal = {}
  loading = true

  get goals(): IGoal[] {
    return this.$store.getters.goals || []
  }

  @Watch('appId', { immediate: true })
  async appIdChanged() {
    if (!this.appId) {
      return
    }

    await this.$store.dispatch(ACTION_GOAL.LOAD_ITEMS, {
      app_id: this.appId,
      is_include_data: true,
    })

    this.loading = false
  }

  onAdd() {
    this.selectedGoal = {
      app_id: this.appId,
    }

    this.modalVisible = true
  }

  onCopy(data: IGoal) {
    const goalCopy = cloneDeep(data)
    goalCopy._id = ''
    goalCopy.title = goalCopy.title + ' Copy'
    this.selectedGoal = goalCopy
    this.modalVisible = true
  }

  onEdit(data: IGoal) {
    this.selectedGoal = data
    this.modalVisible = true
  }

  async onDelete(goal: IGoal) {
    this.$q
      .dialog({
        title: this.$t('confirm'),
        message: this.$t('are_you_sure_you_want_to_delete'),
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const exists = await this.checkItemExists(goal)
        if (!exists) {
          const success = await this.$store.dispatch(ACTION_GOAL.DELETE, goal._id)
          if (success) {
            this.$q.notify({
              message: this.$t('messages.deleted'),
              color: 'positive',
            })
          }
        }
      })
  }

  onCloseModal() {
    this.modalVisible = false
    this.selectedGoal = {}
  }

  // eslint-disable-next-line
  async onDragEnd(evt: any, finalList: IGoal[]) {
    if (!evt.moved) {
      return
    }
    const record = evt.moved.element
    const finalPosition = calcOrderingDragend(record, finalList)

    await this.$store.dispatch(ACTION_GOAL.UPDATE, {
      ...record,
      ordering: finalPosition,
    })
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  async checkItemExists(goal: IGoal) {
    const { dispatch } = this.$store
    const params: IValidRequest = {
      app_id: this.appId,
      initial_id: '',
      push_id: '',
      card_id: goal._id ?? '',
      card_type: 'goal',
      total_messages: 0,
      campaign: {},
    }
    const resp = await dispatch(ACTION_APP.CHECK_ITEM_EXISTS, params)

    if (resp.campaign_title) {
      this.$q.dialog({
        title: this.$t('error'),
        message: this.$t('messages.item_exists_in_campaign', { names: resp.campaign_title.toString() }),
        persistent: true,
        html: true,
      })
      return true
    }
    return false
  }
}
