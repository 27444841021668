
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import Questions from '@/components/question/Questions.vue'
import Goals from '@/components/goal/Goals.vue'
import Messages from '@/components/messages/Messages.vue'
import Forms from '@/components/form/Forms.vue'

@Options({
  components: {
    Messages,
    Questions,
    Goals,
    Forms,
  },
  directives: { maska },
  emits: ['update:changeDefault'],
})
export default class AssetsTabsPanel extends Vue {
  get appId() {
    return this.$route.params.app_id
  }

  bar = 'messages'
  get tabs() {
    return this.bar
  }

  set tabs(tab: string) {
    this.bar = tab
  }

  changeDefault(event) {
    this.$emit('update:changeDefault', event)
  }
}
