<template>
  <section>
    <CLoading :loading="loading" />
    <q-table
      bordered
      flat
      dense
      title="Questions"
      :rows="questions"
      row-key="title"
      :rows-per-page-options="[10]"
      separator="cell"
      :no-data-label="$t('messages.no_data_available')"
      class="text-grey-8"
    >
      <template v-slot:header="props">
        <q-tr>
          <q-th key="action" rowspan="2" class="text-left" style="width: 140px"></q-th>
          <q-th :props="props" key="title" rowspan="2">
            <span class="text-bold">{{ $t('label.question_answer.question_answer_name') }} </span>
          </q-th>
          <q-th :props="props" key="answers" rowspan="2">
            <span class="text-bold">{{ $t('label.question_answer.answers') }} </span>
          </q-th>
          <q-th :props="props" key="applied_campain" rowspan="2" class="text-center">
            <span class="text-bold">{{ $t('label.question_answer.applied_campaign') }} </span>
          </q-th>
        </q-tr>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props" :index="props.rowKey">
          <q-td key="action" :props="props.index">
            <q-btn size="sm" outline round icon="edit" @click="onEdit(props.row)" class="q-mr-sm" />
            <q-btn
              size="sm"
              outline
              round
              color="grey"
              icon="content_copy"
              @click="onCopy(props.row)"
              class="q-mr-sm"
            />
            <q-btn size="sm" outline round color="red" icon="delete" @click="onDelete(props.row)" />
          </q-td>
          <q-td key="title" :props="props">
            {{ props.row.title }} <TagContent :tagValue="props.row.display_id"></TagContent>
          </q-td>
          <q-td key="answers" :props="props">
            <q-chip
              color="primary"
              text-color="white"
              v-for="(item, index) in getAnswers(props.row.answers)"
              :key="index"
            >
              {{ item.label || item.title }}
            </q-chip>
          </q-td>
          <q-td key="applied_campain" :props="props" text-center>
            <q-chip color="primary" text-color="white">
              {{ numberWithCommas(props.row.applied_campain) }}
            </q-chip>
          </q-td>
        </q-tr>
      </template>

      <template v-slot:top separator>
        <h2 class="text-h6 text-black">{{ $t('label.question_answer.question_list') }}</h2>
        <q-space />
        <q-btn no-caps color="primary" @click="onAdd()" :label="$t('add_new')" />
      </template>
    </q-table>
  </section>
  <QuestionFormModal
    v-if="modalVisible"
    :modalVisible="modalVisible"
    :data="selectedQuestion"
    @update:closeModal="onCloseModal"
  />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IAnswer, IImageAnswer, IQuestion, ITappableArea, ITextAnswer, IValidRequest } from '@/utils/types'
import { ACTION_APP, ACTION_QUESTION } from '@/store/actions'
import { calcOrderingDragend } from '@/utils/helpers'

import QuestionFormModal from '@/components/question/QuestionFormModal.vue'
import draggable from 'vuedraggable'
import cloneDeep from 'lodash/cloneDeep'
import { constant } from '@/utils/constants'
import { Formater } from '@/utils/formater'
import CLoading from '@/components/common/ui/CLoading.vue'
import TagContent from '@/components/common/ui/TagContent.vue'

@Options({
  components: {
    draggable,
    QuestionFormModal,
    CLoading,
    TagContent,
  },
  directives: { maska },
})
export default class Questions extends Vue {
  @Prop()
  appId!: string

  modalVisible = false
  loading = true
  selectedQuestion: IQuestion = {}

  get questions(): IQuestion[] {
    return this.$store.getters.questions
  }

  getAnswers(data: IAnswer) {
    let result: ITextAnswer[] | IImageAnswer[] | ITappableArea[]
    if (data.type === constant.ANSWER_TYPE.TEXT) {
      result = data.text_answers ?? []
    } else if (data.type === constant.ANSWER_TYPE.IMAGE) {
      result = data.image_answers ?? []
    } else {
      result = data.imagemap_answer?.tappable_area ?? []
    }
    return result
  }

  @Watch('appId', { immediate: true })
  async appIdChanged() {
    if (!this.appId) {
      return
    }

    await this.$store.dispatch(ACTION_QUESTION.LOAD_ITEMS, {
      app_id: this.appId,
      is_include_data: true,
    })

    this.loading = false
  }

  answerName(id: string) {
    for (const element of this.questions) {
      if (id === element._id) {
        return element.title
      }
    }
  }

  onAdd() {
    this.selectedQuestion = {
      app_id: this.appId,
    }

    this.modalVisible = true
  }

  onCopy(data: IQuestion) {
    const questionCopy = cloneDeep(data)
    questionCopy._id = ''
    questionCopy.title = questionCopy.title + ' Copy'
    this.selectedQuestion = questionCopy
    this.modalVisible = true
  }

  onEdit(data: IQuestion) {
    this.selectedQuestion = data
    this.modalVisible = true
  }

  async onDelete(question: IQuestion) {
    this.$q
      .dialog({
        title: this.$t('confirm'),
        message: this.$t('are_you_sure_you_want_to_delete'),
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const exists = await this.checkItemExists(question)
        if (!exists) {
          const success = await this.$store.dispatch(ACTION_QUESTION.DELETE, question._id)
          if (success) {
            this.$q.notify({
              message: this.$t('messages.deleted'),
              color: 'positive',
            })
          }
        }
      })
  }

  onCloseModal() {
    this.modalVisible = false
    this.selectedQuestion = {}
  }

  // eslint-disable-next-line
  async onDragEnd(evt: any, finalList: IQuestion[]) {
    if (!evt.moved) {
      return
    }

    const record = evt.moved.element
    const finalPosition = calcOrderingDragend(record, finalList)

    await this.$store.dispatch(ACTION_QUESTION.UPDATE, {
      ...record,
      ordering: finalPosition,
    })
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  async checkItemExists(question: IQuestion) {
    const { dispatch } = this.$store
    const params: IValidRequest = {
      app_id: this.appId,
      initial_id: '',
      push_id: '',
      card_id: question._id ?? '',
      card_type: 'question',
      total_messages: 0,
      campaign: {},
    }
    const resp = await dispatch(ACTION_APP.CHECK_ITEM_EXISTS, params)

    if (resp.campaign_title) {
      this.$q.dialog({
        title: this.$t('error'),
        message: this.$t('messages.item_exists_in_campaign', { names: resp.campaign_title.toString() }),
        persistent: true,
        html: true,
      })
      return true
    }
    return false
  }
}
</script>
